import livros from './imagens/Books.svg'
import Relogio from './imagens/Clock.svg'
import dash from './imagens/Dashboard.svg'
import coracao from './imagens/Heart.svg'
import laptop from './imagens/Laptop.svg'
import lampada from './imagens/Light.svg'
import mensagem from './imagens/Messages.svg'
import lapis from './imagens/Pencil.svg'
import pino from './imagens/Pin.svg'
import cell from './imagens/Vector.svg'
import logotipo from './imagens/Carol-Irrael---Logotipo-3.png'
import passar from './imagens/>.png'
import voltar from './imagens/<.png'


const Fotos = {
    Livros: livros,
    Relogio: Relogio,
    Dash: dash,
    Coracao: coracao,
    Laptop: laptop,
    Lampada: lampada,
    Mensagem: mensagem,
    Lapis: lapis,
    Pino: pino,
    Cell: cell,
    Logo: logotipo,
    Passar: passar,
    Voltar: voltar
}

export default Fotos