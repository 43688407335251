import './App.css';
import Carol from './imagens/Carol.png'
import Fotos from './fotos';
import MARCADOS from './geramarcados';
import supabase from './supabase';



function App() {
  
  console.table(MARCADOS)  
  const OPCOES = {
    weekday: 'short',
    day:'numeric',
    month:'numeric'
  }

  function dias() {
    const PROXIMOSTRES = [];
    let CONTADOR = 0;
    const HOJE = new Date(); // Salva a data de hoje
    const PROXIMA_SEXTA = new Date();
    
    // Ajusta a data para a próxima sexta-feira
    PROXIMA_SEXTA.setDate(HOJE.getDate() + (12 - HOJE.getDay()) % 7); // Calcula a próxima sexta
    
    while (PROXIMOSTRES.length < 9 || PROXIMOSTRES[PROXIMOSTRES.length - 1] < PROXIMA_SEXTA) {
        const AMANHA = new Date();
        AMANHA.setDate(HOJE.getDate() + CONTADOR++);
        
        // Evita sábado e domingo
        if (AMANHA.getDay() !== 0 && AMANHA.getDay() !== 6) {
            PROXIMOSTRES.push(new Date(AMANHA)); // Adiciona uma cópia da data
        }
    }
    console.log(PROXIMOSTRES)
    return PROXIMOSTRES;
}

  function criarbotaodia(){
    const DIAS = dias()
    const CONTAINER = document.getElementById("dias");
    for (var i = 0; i < DIAS.length; i++){
      let BOTAO_DIA = document.createElement('input')
      BOTAO_DIA.setAttribute('type', 'radio');
      BOTAO_DIA.setAttribute('name', 'dia');    
      BOTAO_DIA.addEventListener('change', CRIAR_BOTAO_HORARIO)
      const DIA_ARRUMADO = DIAS[i].toLocaleDateString('pt-BR', OPCOES)//arruma o dia//
      BOTAO_DIA.setAttribute('id', DIA_ARRUMADO)
      if (i === 0){
        BOTAO_DIA.setAttribute('checked', 'checked')
      }
      CONTAINER.appendChild(BOTAO_DIA)//cria botao
  
      let LABEL_DIA = document.createElement('label');
      LABEL_DIA.setAttribute('for', DIA_ARRUMADO)
      LABEL_DIA.textContent = DIA_ARRUMADO.replace('.,','').toUpperCase()
      CONTAINER.appendChild(LABEL_DIA)

      if(i < 4){
        let DIVISAO = document.createElement('div')
        DIVISAO.setAttribute('class', 'divisao')
        CONTAINER.appendChild(DIVISAO)
      }
    }
    CRIAR_BOTAO_HORARIO()
  }

  function HORAS(){
    const HORAS_ATIVAS = []
    let CONTADOR = 0
    while(HORAS_ATIVAS.length < 16){
      let HORA = new Date()
      HORA.setHours(9,0,0)
      HORA.setMinutes(40*CONTADOR++)
      HORAS_ATIVAS.push(HORA)
    }
    return HORAS_ATIVAS
  }

  function ARRUMAR_HORA(){
    const HORAS_ARRUMADAS = []
    let HORAS_BRUTAS = HORAS()
    HORAS_BRUTAS.forEach(function(horario){
      var HORA_ARRUMADA = horario.toLocaleTimeString("pt-BR", {hour:'2-digit', minute:'2-digit'})
      HORAS_ARRUMADAS.push(HORA_ARRUMADA)
    })
    return HORAS_ARRUMADAS
  }

  function CRIAR_BOTAO_HORARIO(){
    let HORAS_ARRUMADAS = ARRUMAR_HORA()
    const CONTAINER = document.getElementById('horarios')
    CONTAINER.innerHTML = ''
    let DIA_SELECIONADO = document.querySelector('input[name="dia"]:checked').id
    HORAS_ARRUMADAS.forEach(hora =>{
      if (MARCADOS.includes(DIA_SELECIONADO + hora) === false){
      let BOTAO_HORARIO = document.createElement('input');
      BOTAO_HORARIO.setAttribute('type', 'radio');
      BOTAO_HORARIO.setAttribute('name', 'horario');
      BOTAO_HORARIO.setAttribute('id', DIA_SELECIONADO + hora);
      if (hora === HORAS_ARRUMADAS[0]){
        BOTAO_HORARIO.setAttribute('checked','checked')
      }
      CONTAINER.appendChild(BOTAO_HORARIO);
     
      let LABEL_HORARIO = document.createElement('label')
      LABEL_HORARIO.setAttribute('for', DIA_SELECIONADO + hora)
      LABEL_HORARIO.textContent = hora.replace(':','h')
      CONTAINER.appendChild(LABEL_HORARIO)
    }
    })
  }
  var emailnaovalido = true
  function validaremail(){
    var email = document.getElementById('Email')
    if (!email.value.match(/^[A-Za-z\._\-0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)){
      email.style.borderColor = 'red'
      email.style.outline = '2px solid red'
      emailnaovalido = true
    } else{
      email.style.outline = '0px'
      emailnaovalido = false
    }
  }
  let NumeroInvalido = true
  function arrumarnumero(){
    const telefoneInput = document.getElementById("Numero");
    let telefone = telefoneInput.value;
    telefone = telefone.replace(/\D/g, "");
    if (telefone.length > 10) {
      telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      telefoneInput.style.borderColor = 'black'
      telefoneInput.style.outline = 'black'
      NumeroInvalido = false
    } else if (telefone.length > 6) {
      telefone = telefone.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else if (telefone.length > 2) {
      telefone = telefone.replace(/(\d{2})(\d{0,5})/, "($1) $2");
    } else {
      telefone = telefone.replace(/(\d*)/, "($1");
      telefoneInput.style.borderColor = 'red'
      telefoneInput.style.outline = 'red'

      }
      telefoneInput.value = telefone;
      ;}
      var jatem = false

  function arrumarinsta(){
    const InstagramInput = document.getElementById('Insta')
    let Insta = InstagramInput.value
    if (Insta.length > 0 && jatem === false){
      Insta = '@' + Insta
      jatem = true
      InstagramInput.style.borderColor = 'black'
    } else if (Insta.length === 0){
      jatem = false
    }
    InstagramInput.value = Insta
    }
  async function salvarsupabase(){
    const INFO = {
      Nome: document.getElementById('Nome').value,
      Email: document.getElementById('Email').value,
      Numero: document.getElementById('Numero').value,
      Instagram: document.getElementById('Insta').value,
      Data: document.querySelector('input[name="horario"]:checked').id
    }
    await supabase
    .from('Clientes') // Substitua pelo nome da sua tabela
    .insert([
      INFO,
    ])
    .select()
  }
  function salvarSheets(){
    const data = {
      Nome: document.getElementById('Nome').value,
      Email: document.getElementById('Email').value,
      Numero: document.getElementById('Numero').value,
      Instagram: document.getElementById('Insta').value,
      Data: document.querySelector('input[name="horario"]:checked').id
    }
    fetch("https://api.sheetmonkey.io/form/nHGPr3BBXmxT26N9iyAAZV", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  function formatarData() {
    const dataString = document.querySelector('input[name = "horario"]:checked').id
    const dataHora = dataString.split(', ')[1];
    const data = dataHora.slice(0, 5); 
    const hora = dataHora.slice(5);  
    const [dia, mes] = data.split('/');
    const [horaFormatada, minuto] = hora.split(':');
    const ano = new Date().getFullYear(); 
    const dataISO = `${ano}-${mes}-${dia}T${horaFormatada}:${minuto}:00`;
    return dataISO;
  }
  function salvarAgenda(){
    const Data = {
      NomeCliente: document.getElementById('Nome').value,
      EmailCliente: document.getElementById('Email').value,
      Comeca: formatarData()
    }
    fetch('https://hook.us1.make.com/46rggpsi5v93nps32xm6w1hccdzrpl2e', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify(Data),
    });
  }
  function separarData(dataString) {
    const diasDaSemana = {
      "seg.": "Segunda-feira",
      "ter.": "Terça-feira",
      "qua.": "Quarta-feira",
      "qui.": "Quinta-feira",
      "sex.": "Sexta-feira",
      "sáb.": "Sábado",
      "dom.": "Domingo"
    };
    const meses = {
      "01": "Janeiro",
      "02": "Fevereiro",
      "03": "Março",
      "04": "Abril",
      "05": "Maio",
      "06": "Junho",
      "07": "Julho",
      "08": "Agosto",
      "09": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro"
    };
    const [diaSemanaAbrev, dataEHora] = dataString.split(/,\s*/);
    const data = dataEHora.slice(0, 5); 
    const horario = dataEHora.slice(5); 
    const [diaMes, mes] = data.split('/');
    return {
      "Dia da semana": diasDaSemana[diaSemanaAbrev],
      "Dia do mês": diaMes,
      "Mês": meses[mes],
      "Horário": horario
    };
  }
  function Mensagem(Numero, Nome, Data){
    const data = separarData(Data)
    const payload = {
      "number":'55'+ Numero.replace(/\D/g, ''),
      "text":'Deu certinho aqui viu '+ Nome + '? Agendado para '+ data['Dia do mês'] +' de '+ data['Mês']+ ' as ' + data['Horário']+', te passo mais detalhes por aqui!! 😚📈📈',
      "delay":123
    }
    const options = {
      method: 'POST',
      headers: {apikey: 'BcKqp7Q6PsAxlVBfZ1GzPAZqG0hb76cTJ880y5YiiGp0ti4AwJ8tNa5SfTfKzr7X', 'Content-Type': 'application/json'},
      body: JSON.stringify(payload)
    };
    
    fetch('https://evolutionapi.digitalnnect.io/message/sendText/Connect', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }
  function SalvarPipedrive(Nome, Email, Numero){
    const apiKey = 'c2b73e72de3a2a7938d56d54149b0db55f49a057'; 
    const apiUrlPersons = 'https://api.pipedrive.com/v1/persons?api_token=' + apiKey;
    const apiUrlDeals = 'https://api.pipedrive.com/v1/leads?api_token=' + apiKey;
    const pessoa = {
    name: Nome,
    email: Email,
    phone: Numero,
    };
    const lead = {
    title: Nome,
      };
const adicionarPessoaELead = async (pessoa, lead) => {
  try {
    // Adicionar a pessoa
    const pessoaResponse = await fetch(apiUrlPersons, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer c2b73e72de3a2a7938d56d54149b0db55f49a057`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pessoa),
    });

    if (!pessoaResponse.ok) {
      throw new Error('Erro ao adicionar a pessoa');
    }

    const pessoaData = await pessoaResponse.json();
    const pessoaId = pessoaData.data.id; 

    // Adicionar a lead associada à pessoa
    const leadData = {
      ...lead,
      person_id: pessoaId,
    };

    const leadResponse = await fetch(apiUrlDeals, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(leadData),
    });

    if (!leadResponse.ok) {
      throw new Error('Erro ao adicionar a lead');
    }

    const leadResult = await leadResponse.json();
    console.log('Pessoa e lead adicionadas com sucesso:', leadResult);
  } catch (error) {
    console.error('Erro:', error);
  }}
   adicionarPessoaELead(pessoa, lead);
  }
  function arrumarFotos(){
    const livros = document.getElementsByClassName('Livros')[0]
    const relogio = document.getElementsByClassName('Relogio')[0]
    const dash = document.getElementsByClassName('Dash')[0]
    const coracao = document.getElementsByClassName('Coracao')[0]
    const laptop = document.getElementsByClassName('Laptop')[0]
    const lampada = document.getElementsByClassName('Lampada')[0]
    const mensagem = document.getElementsByClassName('Mensagem')[0]
    const lapis = document.getElementsByClassName('Lapis')[0]
    const pino = document.getElementsByClassName('Pino')[0]
    const cell = document.getElementsByClassName('Cell')[0]
    const logo = document.getElementsByClassName('Logo')[0]
    relogio.style.left = 45 + '%'
    coracao.style.left = 90 + '%'
    laptop.style.left = 80 + '%'
    lapis.style.left = 50 + '%'
    pino.style.left = 70 + '%'
    mensagem.style.left = 80 + '%'
    cell.style.left = 30 + '%'
    lampada.style.left = 20 + '%'
    logo.style.opacity = 0
    dash.style.left = 10 + '%'
    livros.style.left = 10 + '%'
  }
  function voltarfotos(){
    const livros = document.getElementsByClassName('Livros')[0]
    const relogio = document.getElementsByClassName('Relogio')[0]
    const dash = document.getElementsByClassName('Dash')[0]
    const coracao = document.getElementsByClassName('Coracao')[0]
    const laptop = document.getElementsByClassName('Laptop')[0]
    const lampada = document.getElementsByClassName('Lampada')[0]
    const mensagem = document.getElementsByClassName('Mensagem')[0]
    const lapis = document.getElementsByClassName('Lapis')[0]
    const pino = document.getElementsByClassName('Pino')[0]
    const cell = document.getElementsByClassName('Cell')[0]
    const logo = document.getElementsByClassName('Logo')[0]
    relogio.style.left = 30 + '%'
    coracao.style.left = 60 + '%'
    laptop.style.left = 45 + '%'
    lapis.style.left = 30 + '%'
    pino.style.left = 40 + '%'
    mensagem.style.left = 50 + '%'
    cell.style.left = 18 + '%'
    lampada.style.left = 4 + '%'
    logo.style.opacity = 20
    dash.style.left = 5 + '%'
    livros.style.left = 5 + '%'
  }

  function Scroll(){
   const containerdias = document.getElementById('dias')
   const botaoesquerda = document.getElementById('esquerda')
   const botaodireita = document.getElementById('direita')
   const largura = window.innerWidth
   const scrollLeftPosition = containerdias.scrollLeft;
   if (scrollLeftPosition < -300 && largura <500) {
    botaoesquerda.style.visibility = 'hidden'; 
    botaodireita.style.visibility = 'visible'; 
  } else if (scrollLeftPosition < 10 && largura > 500) {
    botaoesquerda.style.visibility = 'hidden'; 
    botaodireita.style.visibility = 'visible'; }
  else if (scrollLeftPosition >= 300 && largura < 500 ) {
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'hidden'; 
  } else if(scrollLeftPosition >=200 && largura > 500){
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'hidden'; 
  } else {
    botaoesquerda.style.visibility = 'visible'; 
    botaodireita.style.visibility = 'visible'; 
  }
  }
  function PassarDias(){
   const containerdias = document.getElementById('dias')
   const maxScrollLeft = containerdias.scrollWidth - containerdias.clientWidth;
   const largura = window.innerWidth
   if(largura > 500){
    containerdias.scrollBy({
      left: maxScrollLeft, 
      behavior: 'smooth'
    })} else {
    containerdias.scrollBy({
      left: 400, 
      behavior: 'smooth'
    })
    }
  }
  function VoltarDias(){
    const containerdias = document.getElementById('dias')
    const maxScrollLeft = containerdias.scrollWidth - containerdias.clientWidth;
    const largura = window.innerWidth
    if (largura > 500){
    containerdias.scrollBy({
      left: -maxScrollLeft, 
      behavior: 'smooth'
    })} else {
      containerdias.scrollBy({
        left: -400, 
        behavior: 'smooth'
      })
      }
  }
  var pagina = 1;
  var created = false
  function Passar(){
    const ContainerIntro = document.getElementById('intro')
    const ContainerContato = document.getElementById('contato')
    const ContainerCalendario = document.getElementById('calendario')
    const ContainerFinal = document.getElementById('Final')
    const Carrossel = document.getElementById('Carrossel')
    const Nome = document.getElementById('Nome')
    const Numero = document.getElementById('Numero')
    const Email = document.getElementById('Email')
    const Insta = document.getElementById('Insta')
    const Logo = document.getElementById('Logo')
    const Carol = document.getElementsByClassName('Carol')[0]
    const largura = window.innerWidth;
    if(pagina === 1){
      if (Nome.value === ''){
        Nome.style.borderColor = 'red'
      } else {
      document.getElementById('greetings').innerText = "Olá " + Nome.value + '!'
      Nome.style.borderColor = 'black'
      Carrossel.style.transform = 'translatex(-800px)'
      Carrossel.style.top = 30 + '%'
      if (largura > 500){
      Logo.style.top = 15 + '%'
    } else {
      Logo.style.top = 5 + '%'
    }
      ContainerContato.style.transition =  'opacity ' + 0.5 +  's ease' 
      ContainerIntro.style.opacity = 0
      ContainerContato.style.opacity = 20

      pagina++}
    } else if (pagina === 2){
      if(NumeroInvalido === true||Insta.value === '' || emailnaovalido === true){
        if(Numero.value.length < 1){
          Numero.style.borderColor = 'red'
        }
        if (emailnaovalido === true){
          Email.style.borderColor = 'red'
        }
        if (Insta.value === ''){
          Insta.style.borderColor = 'red'
        }
      } else{
      document.getElementById('dias').scrollLeft = -200
      Numero.style.borderColor = 'black'
      Email.style.borderColor = 'black'
      Insta.style.borderColor = 'black'
      ContainerContato.style.visibility = 'hidden'
      ContainerCalendario.style.opacity = 20
      ContainerCalendario.style.visibility = 'visible'
      if (largura > 500){
      Carrossel.style.transform = 'translatex(-1300px)'
      Carrossel.style.top = 15+ '%'
      Carol.style.left = '2000px'
      arrumarFotos()}
      else{
      Carrossel.style.transform = 'translatex(-1615px)'
      }
      if (created === false){
      criarbotaodia()
      created = true}
      pagina++}
    } else if (pagina === 3){
      const HoraSelecionada = document.querySelector('input[name = "horario"]:checked').id
      salvarsupabase()
      salvarSheets()
      salvarAgenda()
      SalvarPipedrive(Nome.value, Email.value, Numero.value)
      Mensagem( Numero.value,Nome.value, HoraSelecionada)
      console.log(HoraSelecionada)
      ContainerCalendario.style.opacity = 0
      setTimeout(() => {
        Carrossel.style.display = 'none';
      }, 500); // 2000 milissegundos = 2 segundos
      if (largura > 500){
        voltarfotos()}
      Carol.style.left = '55%'
      ContainerFinal.style.visibility = 'visible'
      ContainerFinal.style.opacity = 20
    }
  }
  function Voltar(){
    const largura = window.innerWidth;
    const ContainerIntro = document.getElementById('intro')
    const ContainerContato = document.getElementById('contato')
    const ContainerCalendario = document.getElementById('calendario')
    const Carrossel = document.getElementById('Carrossel')
    const Carol = document.getElementsByClassName('Carol')[0]
    const Logo = document.getElementById('Logo')
    if(pagina === 2){
      Carrossel.style.transform = 'translatex(0px)'
      Carrossel.style.top = 40 + '%'
      ContainerIntro.style.opacity = 20
      ContainerContato.style.transition = 'none'
      ContainerContato.style.opacity = 0 
      Logo.style.top = 25 + '%' 
      pagina--
    } else if(pagina === 3){
      ContainerContato.style.visibility = 'visible'
      ContainerCalendario.style.opacity = 0
      ContainerCalendario.style.visibility = 'hidden'
      Carrossel.style.transform = 'translatex(-800px)'
      Carrossel.style.top = 30+ '%'
      Carol.style.left = '55%'
      if (largura > 500){
      voltarfotos()}
      pagina --
    }
  }
  return (
    <div className="App">
      <div className='imagens'>
      <img src={Fotos['Livros']} className='Livros' alt='Livros'></img>
      <img src={Fotos['Relogio']} className='Relogio' alt='Relogio'></img>
      <img src={Fotos['Dash']} className='Dash' alt='Dash'></img>
      <img src={Fotos['Coracao']} className='Coracao' alt='Coracao'></img>
      <img src={Fotos['Laptop']} className='Laptop' alt='Laptop'></img>
      <img src={Fotos['Lampada']} className='Lampada' alt='Lampada'></img>
      <img src={Fotos['Mensagem']} className='Mensagem' alt='Mensagem'></img>
      <img src={Fotos['Lapis']} className='Lapis' alt='Lapis'></img>
      <img src={Fotos['Pino']} className='Pino' alt='Pino'></img>
      <img src={Fotos['Cell']} className='Cell' alt='Cell'></img>
      <img src={Fotos['Logo']} className='Logo' id='Logo' alt='Logo'></img>
      </div>
      
      <div id='Carrossel' className='Carrossel' >
        <div id='intro' className='setor' >
          <h1>Olá</h1>
          <h3>Agradecemos o interesse, precisamos apenas de algumas informações para marcarmos nosso bate-papo</h3>
          <input type='text' id='Nome' className='caixaderesposta' placeholder=' Nome'></input>
          <div className='botot'>
          <input type='button' value='Próximo' className='botoesbt' id='prosseguir'  onClick={Passar}></input>
          </div>
        </div>
        <div id='contato' className='setor' style={{opacity: 0}}>
          <h1 id='greetings'></h1>
          <h3>Agora nos informe seus dados de contato:</h3>
          <label className='label2' htmlFor='Numero'>Numero:</label><br></br>
          <input type='tel' id='Numero' maxLength={15} onInput={arrumarnumero} className='caixaderesposta'></input><br></br>
          <label className='label2'  htmlFor='Email'>Email:</label><br></br>
          <input type='email' onChange={validaremail} id='Email' className='caixaderesposta'></input><br></br>
          <label className='label2' htmlFor='Insta'>Insta</label><br></br>
          <input type='text' id='Insta' onInput={arrumarinsta} className='caixaderesposta' placeholder='@'></input><br></br>
          <div className='botot'>
          <input type='button' value='Próximo'  id='prosseguir' className='botoesbt' onClick={Passar}></input>
          <input type='button' value='Voltar'  id='voltar' className='botoesvt' onClick={Voltar}></input>
          </div>
        </div>
        <div id='calendario' className='setorC' style={{opacity:0}}>
          <h1 className='noborda1'>Perfeito!</h1>
          <h3 className='noborda'>Agora selecione o horario que melhor se encaixa na sua agenda</h3>
          <div className='calendarioebts'>
            <input type='button' onClick={VoltarDias} id='<' className='VoltarDias'></input>
            <label htmlFor='<' id='esquerda' ><img src={Fotos['Voltar']} className='imagembotao2' alt='BotaoVoltar'></img></label>
              <div className='calendario'>
                <div className='dias' id='dias' onScroll={Scroll}></div>
                <div className='horarios' id='horarios'></div>
              </div>
            <input type='button' onClick={PassarDias} id='>' className='PassarDias'></input>
            <label htmlFor='>' id='direita'><img src={Fotos['Passar']} className='imagembotao1' alt='BotaoPassar'></img></label>
          </div>          
          <div className='botot'>
            <input type='button' value='Próximo'  id='prosseguir' className='botoesbt' onClick={Passar}></input>
            <input type='button' value='Voltar'  id='voltar' className='botoesvt' onClick={Voltar}></input>
          </div>
        </div>
      </div>
      <div id='Final'  className='Final' >
          <h1>Obrigada pelo contato</h1>
          <h3>Agora fique atento no celular que em breve uma de nossas atendentes entrará em contato</h3>
      </div>
      <img src={Carol} alt='Carol' className='Carol'></img>
    </div>
  );
}

export default App;
